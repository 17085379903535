exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.hash || location.pathname.includes('article')) {
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => {
      sessionStorage.setItem(
        `@@scroll|${location.pathname}|initial`,
        currentPosition[1],
      );
    }, 0);
  }
};
